import React, { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import * as Action from "../../actions/adminAction";
import { ArrowBackIosNew, Visibility, VisibilityOff } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
  Typography,
  Button,
  Paper,
  CardContent,
  Modal,
  IconButton,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Alert,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const User = [
  {
    id: 1,
    label: "admin",
    key: 'admin'
  },
  {
    id: 2,
    label: "district coordinator",
    key: 'dc'
  },
  {
    id: 3,
    label: "trainer",
    key: 'trainer'
  },
  // {
  //   id: 4,
  //   label: "gramsevak",
  //   key: 'gramsevak'
  // },
  // {
  //   id: 5,
  //   label: "sarpanch",
  //   key: 'sarpanch'
  // },
  // {
  //   id: 6,
  //   label: "beneficiary",
  //   key: 'beneficiary'
  // },
  {
    id: 7,
    label: "quality checker",
    key: 'qc'
  },
  {
    id: 8,
    label: 'Client',
    key: 'client'
  }
];

const jobRoles = [
  {
    id: 1,
    value: 'A'
  },
  {
    id: 2,
    value: 'B'
  },
  {
    id: 3,
    value: 'C'
  },
  {
    id: 4,
    value: 'D'
  }
]

const data = [
  {
    PanchyatName: "ADEGAON",
    description: "Implementing Bhujal Yojana",
    TalukaName: "Bhor",
    DistrictName: "Pune",
    SarpanchName: "XYZ",
    GramsevakName: "auyda",
  },
];

export const AddUser = ({
  getAllDistrictData,
  getAllTalukaData,
  getAllgrampanchayatdata,
  getDistrictWiseTalukaData,
  getTalukaWisePanchayat,
  getAllTrainingModuleData,
}) => {

  const classes = useStyles();
  const navigate = useNavigate();

  // State to manage checkbox (Same as Current Address)
  const [isSameAsCurrent, setIsSameAsCurrent] = useState(false);

  //data variables
  const [usertype, setUserType] = useState("");
  const [preferredGramPanchayat, setpreferredGramPanchayat] = useState("");
  const [preferredDistrict, setpreferredDistrict] = useState("");
  const [preferredtaluka, setpreferredTaluka] = useState("");

  const [TalukaData, setTalukaData] = useState([]);
  const [GramPanchayatData, setGramPanchayatData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [PermanantDistrictData, setPermanantDistrictData] = useState([])
  const [PermanantTalukaData, setPermanantTalukaData] = useState([])
  const [PermanantPanchayatData, setPermanantPanchayatData] = useState([])
  const [PreferredDistrictData, setPreferredDistrictData] = useState([])
  const [PreferredTalukaData, setPreferredTalukaData] = useState([])
  const [PreferredPanchayatData, setPreferredPanchayatData] = useState([])

  const [loading, setloading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [whatsup, setwhatsup] = useState("");
  const [email, setEmail] = useState("");
  const [currentAdd, setCurrentAdd] = useState("");
  const [currentAddlinetwo, setcurrentAddlinetwo] = useState("");
  const [currentstate, setcurrentstate] = useState("");
  const [pincode, setPincode] = useState("");
  const [District, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [GramPanchayat, setGramPanchayat] = useState("");
  const [permanentAddlinetwo, setpermanentAddlinetwo] = useState("");
  const [permanentAdd, setPermanentAdd] = useState("");
  const [permanentpincode, setpermanentPincode] = useState("");
  const [permanentstate, setpermanentstate] = useState("");
  const [passwordval, setPasswordval] = useState("");
  const [PermanentGramPanchayat, setPermanentGramPanchayat] = useState("");
  const [PermanentDistrict, setPermanentDistrict] = useState("");
  const [Permanenttaluka, setPermanentTaluka] = useState("");


  const [imgArray, setImgArray] = useState();
  const [showPassword, setShowPassword] = useState(false);

  //Error variables
  const [error, setError] = useState(false);
  const [fnameError, setfnameError] = useState(true);
  const [lnameError, setlnameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [contactError, setcontactError] = useState(true);
  const [whatsupcontactError, setwhatsupcontactError] = useState(true);
  const [currAddError, setcurrAddError] = useState(true);
  const [currAddtwoError, setcurrAddtwoError] = useState(true);
  const [currAddstateError, setcurrAddstateError] = useState(true);
  const [currAdddistrictError, setcurrAdddistrictError] = useState(true);
  const [currAddtalukaError, setcurrAddtalukaError] = useState(true);
  const [currAddgrampanchaytError, setcurrAddgrampanchaytError] = useState(false);
  const [pinError, setpinError] = useState(true);
  const [PermaAddError, setPermaAddError] = useState(true);
  const [PermaAddtwoError, setPermaAddTwoError] = useState(true);
  const [PermaAddstateError, setPermaAddstateError] = useState(true);
  const [permaAdddistrictError, setpermaAdddistrictError] = useState(true);
  const [permaAddtalukaError, setpermaAddtalukaError] = useState(true);
  const [permaAddgrampanchayatError, setpermaAddgrampanchayatError] = useState(false);
  const [permapinError, setPermapinError] = useState(true);
  const [passError, setPassError] = useState(true);
  const [usertypeError, setusertypeError] = useState(true);
  const [distError, setdistError] = useState(false);
  const [talukaError, settalukaError] = useState(false);
  const [GramPanchayatError, setGramPanchayatError] = useState(false);
  // const [page, setPage] = React.useState(0);
  const [userExist, setUserExist] = useState(false)
  const [qualification, setQualification] = useState('');
  const [jobRoleData, setJobroleData] = useState([]);
  const [jobRole, setJobrole] = useState('');
  const [jobRoleErr, setJobroleErr] = useState(true);

  const getDistrict = async () => {
    const result = await getAllDistrictData();
    if (result !== "No data found") {
      setDistrictData(result);
      setPermanantDistrictData(result);
      setPreferredDistrictData(result);
    }
  };

  const getTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: District?._id
    })
    if (result !== 'No data found') {
      setTalukaData(result)
    } else {
      settalukaError(true)
    }
  }

  const getPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: District?._id,
      taluka_id: taluka?._id
    })
    // if (result !== 'No data found') {
    //   setGramPanchayatData(result)
    // } else {
    //   setGramPanchayatError(true)
    // }
  }

  const getPermantTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: PermanentDistrict?._id
    })
    if (result !== 'No data found') {
      setPermanantTalukaData(result)
    } else {
      settalukaError(true)
    }
  }

  const getPermantPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: PermanentDistrict?._id,
      taluka_id: Permanenttaluka?._id
    })
    // if (result !== 'No data found') {
    //   setPermanantPanchayatData(result)
    // } else {
    //   setGramPanchayatError(true)
    // }
  }

  const getPreferredTaluka = async () => {
    settalukaError(false)
    const result = await getDistrictWiseTalukaData({
      distric_id: preferredDistrict?._id
    })
    if (result !== 'No data found') {
      setPreferredTalukaData(result)
    } else {
      settalukaError(true)
    }
  }

  const getPreferredPanchayat = async () => {
    setGramPanchayatError(false)
    const result = await getTalukaWisePanchayat({
      distric_id: preferredDistrict?._id,
      taluka_id: preferredtaluka?._id
    })
    if (result !== 'No data found') {
      setPreferredPanchayatData(result)
    } else {
      setGramPanchayatError(true)
    }
  }

  const getAllTrainingModuleDataAsJobRole = async () => {
    setJobroleErr(false)
    const result = await getAllTrainingModuleData()
    // console.log("----getAllTrainingModuleDataAsJobRole----",result)
    if (result !== 'No data found') {
      setJobroleData(result)
    } else {
      setJobroleErr(true)
    }
  }

  useEffect(() => {
    getDistrict();
    getAllTrainingModuleDataAsJobRole();
  }, []);

  useEffect(() => {
    if (District) {
      getTaluka()
    }
  }, [District])

  useEffect(() => {
    if (taluka) {
      getPanchayat()
    }
  }, [taluka])

  useEffect(() => {
    if (PermanentDistrict && !isSameAsCurrent) {
      getPermantTaluka()
    }
  }, [PermanentDistrict])

  useEffect(() => {
    if (Permanenttaluka && !isSameAsCurrent) {
      getPermantPanchayat()
    }
  }, [Permanenttaluka])

  useEffect(() => {
    if (preferredDistrict) {
      getPreferredTaluka()
    }
  }, [preferredDistrict])

  useEffect(() => {
    if (preferredtaluka) {
      getPreferredPanchayat()
    }
  }, [preferredtaluka])


  const validate = () => {
    let IsformValid = true;

    if (!firstName) {
      IsformValid = false;
      setfnameError(true);
    } else if (firstName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (middleName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!lastName) {
      IsformValid = false;
      setlnameError(true);
    } else if (lastName.match(/[0-9+@#$&%!~]/)) {
      IsformValid = false;
      setError(true);
    }

    if (!contact) {
      IsformValid = false;
      setcontactError(true);
    } else if (
      contact.match(/[A-Za-z+@#$&%!~]/) ||
      (contact.length !== 10 && contact.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!email) {
      IsformValid = false;
      setEmailError(true);
    } else if (
      !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) &&
      email.length !== 0
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!passwordval) {
      IsformValid = false;
      setPassError(true);
    }

    if (!usertype) {
      IsformValid = false;
      setusertypeError(true);
    }

    if (!District) {
      IsformValid = false;
      setcurrAdddistrictError(true);
    }

    if (!taluka) {
      IsformValid = false;
      setcurrAddtalukaError(true);
    }

    // if (!GramPanchayat) {
    //   IsformValid = false;
    //   setcurrAddgrampanchaytError(true);
    // }
    if (!whatsup) {
      setwhatsupcontactError(true);
    }
    if (!currentAdd) {
      IsformValid = false;
      setcurrAddError(true);
    }
    if (!currentAddlinetwo) {
      setcurrAddtwoError(true);
    }
    if (!currentstate) {
      setcurrAddstateError(true);
    }

    if (!pincode) {
      IsformValid = false;
      setpinError(true);
    } else if (
      pincode.match(/[A-Za-z+@#$&%!~]/) ||
      (pincode.length !== 6 && pincode.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }

    if (!permanentAdd) {
      IsformValid = false;
      setPermaAddError(true);
    }
    if (!permanentAddlinetwo) {
      setPermaAddTwoError(true);
    }
    if (!permanentstate) {
      setPermaAddstateError(true);
    }
    if (!PermanentDistrict) {
      IsformValid = false;
      setpermaAdddistrictError(true);
    }
    if (!Permanenttaluka) {
      IsformValid = false;
      setpermaAddtalukaError(true)
    }
    // if (!PermanentGramPanchayat) {
    //   IsformValid = false;
    //   setpermaAddgrampanchayatError(true);
    // }

    if (!permanentpincode) {
      IsformValid = false;
      setPermapinError(true);
    } else if (
      permanentpincode.match(/[A-Za-z+@#$&%!~]/) ||
      (permanentpincode.length !== 6 && permanentpincode.length !== 0)
    ) {
      IsformValid = false;
      setError(true);
    }


    if (!jobRole) {
      setJobroleErr(true);
    }
    return IsformValid;
  };

  const handleSubmit = async () => {
    setfnameError(false);
    setlnameError(false);
    setEmailError(false);
    setcontactError(false);
    setPassError(false);
    setwhatsupcontactError(false);
    setusertypeError(false);
    setdistError(false);
    settalukaError(false);
    setcurrAddError(false);
    setcurrAddtwoError(false);
    setcurrAddstateError(false);
    setcurrAdddistrictError(false);
    setcurrAddtalukaError(false);
    // setcurrAddgrampanchaytError(false);
    setpinError(false);
    setPermaAddError(false);
    setPermaAddTwoError(false);
    setPermaAddstateError(false);
    setpermaAdddistrictError(false);
    setpermaAddtalukaError(false);
    setGramPanchayatError(false);
    // setpermaAddgrampanchayatError(false);
    setPermapinError(false)
    setJobroleErr(false);

    if (validate()) {
      const contactNo = contact;
      const emailId = email;

      const whatsappNo = whatsup;
      const currentAddressLine1 = currentAdd;
      const currentAddressLine2 = currentAddlinetwo;
      const currentAddressPincode = pincode;
      const currentAddressGrampanchayat = GramPanchayat?.locationName;
      const currentAddressState = currentstate;
      const currentAddressTaluka = taluka?.talukaName;
      const currentAddressDistrict = District?.districtName;
      const permanentAddressLine1 = permanentAdd;
      const permanentAddressLine2 = permanentAddlinetwo;
      const permanentAddressPincode = permanentpincode;
      const permanentAddressTaluka = Permanenttaluka?.talukaName;
      const permanentAddressDistrict = PermanentDistrict?.districtName;
      const permanentAddressState = permanentstate;
      const permanentAddressGrampanchayat = PermanentGramPanchayat?.locationName;
      const preferredGrampanchayat_id = preferredGramPanchayat._id;
      const preferredGrampanchayatName =
        preferredGramPanchayat?.locationName;
      const preferredTalukaName = preferredtaluka?.talukaName;
      const preferredTaluka_id = preferredtaluka?._id;
      const preferredDistrict_id = preferredDistrict?._id;
      const preferredDistrictName = preferredDistrict?.districtName;
      const profilePicURL = imgArray;
      const userType = usertype?.key;
      const password = passwordval;

      const userData = {
        firstName,
        middleName,
        lastName,
        contactNo,
        whatsappNo,
        emailId,
        currentAddressLine1,
        currentAddressLine2,
        currentAddressPincode,
        currentAddressTaluka,
        currentAddressDistrict,
        currentAddressState,
        currentAddressGrampanchayat,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressPincode,
        permanentAddressTaluka,
        permanentAddressDistrict,
        permanentAddressState,
        permanentAddressGrampanchayat,
        preferredGrampanchayat_id,
        preferredGrampanchayatName,
        preferredTaluka_id,
        preferredTalukaName,
        preferredDistrict_id,
        preferredDistrictName,
        password,
        profilePicURL,
        userType,
        qualification: qualification,
        currentProjectExperties: jobRole
      };
      setloading(true);
      await axios
        .post(process.env.REACT_APP_BASE_URL + "/user/insertuser", userData)
        .then((responce) => {
          if (responce.data !== "Mobile number already exist") {
            alert("User Added Successfully");
            setloading(false);
            navigate("/admin/user-list");
          } else {
            setloading(false)
            setUserExist(true)
          }
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      console.log("Form Invalid");
    }
  };


  return (
    <center>
      <div className={classes.mainClass}>
        <Typography
          variant="h3"
          sx={{ marginTop: 7, fontWeight: 600 }}
          component="h2"
        >
          ADD USER
        </Typography>

        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          startIcon={<ArrowBackIosNew />}
          onClick={(e) => {
            navigate("/admin/user-list");
          }}
        >
          Go back
        </Button>
        <div>
          <Paper className={classes.profilemodal}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* for profile details */}
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#3A3A56', color: 'white', padding: 10, letterSpacing: '1px' }}>Personal Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-fname"
                      label="First Name"
                      error={fnameError}
                      value={firstName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFirstName(value);
                        if (value.trim() === "" || /[0-9+@#$&%!~]/.test(value)) {
                          setfnameError(true);
                        } else {
                          setfnameError(false);
                        }
                      }}
                      helperText={
                        fnameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter First Name
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={middleName.trim().match(/[0-9+@#$&%!~]/)}
                      id="outlined-adornment-middle"
                      label="Middle-Name"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={lnameError}
                      id="outlined-adornment-lname"
                      label="Last-Name"
                      value={lastName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setLastName(value);
                        if (value.trim() === "" || /[0-9+@#$&%!~]/.test(value)) {
                          setlnameError(true);
                        } else {
                          setlnameError(false);
                        }
                      }}

                      helperText={
                        lnameError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter last Name
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={emailError}
                      id="outlined-adornment-email"
                      label="Email"
                      value={email}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);
                        if (value.trim() === "" && !email.match(
                          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                        ) && email.length !== 0) {
                          setEmailError(true);
                        } else {
                          setEmailError(false);
                        }
                      }}
                      helperText={
                        emailError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter email
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-contact"
                      label="Contact No"
                      error={contactError}
                      value={contact}
                      onChange={(e) => {
                        const value = e.target.value;
                        setContact(value);
                        if (value.match(/[^0-9]/) || value.length !== 10 && value.length !== 0) {
                          setcontactError(true);
                        } else {
                          setcontactError(false);
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                      helperText={
                        contactError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter a Valid Contact Number
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={whatsupcontactError}
                      id="outlined-adornment-whatsapp"
                      label="Whatsup Number"
                      value={whatsup}
                      onChange={(e) => {
                        const value = e.target.value;
                        setwhatsup(value);
                        if (value.match(/[^0-9]/) || value.length !== 10 && value.length !== 0) {
                          setwhatsupcontactError(true);
                        } else {
                          setwhatsupcontactError(false);
                        }
                      }}
                      inputProps={{ maxLength: 10 }}
                      helperText={
                        whatsupcontactError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter whatsup Number
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>

                {/* for current address */}
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#3A3A56', color: 'white', padding: 10, letterSpacing: '1px' }}>Current Address Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }} >
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-address1"
                      label="Current-Address line 1"
                      value={currentAdd}
                      onChange={(e) => {
                        const value = e.target.value;
                        setCurrentAdd(value);
                        if (value.trim() === "") {
                          setcurrAddError(true);
                        } else {
                          setcurrAddError(false);
                        }
                      }}
                      error={currAddError}
                      helperText={
                        currAddError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address line 1
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-address2"
                      label="Current-Address line 2"
                      value={currentAddlinetwo}
                      onChange={(e) => {
                        const value = e.target.value;
                        setcurrentAddlinetwo(value);
                        if (value.trim() === "") {
                          setcurrAddtwoError(true);
                        } else {
                          setcurrAddtwoError(false);
                        }
                      }}
                      error={currAddtwoError}
                      helperText={
                        currAddtwoError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address line 2
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-state1"
                      label="current address state"
                      value={currentstate}
                      onChange={(e) => {
                        const value = e.target.value;
                        setcurrentstate(value);
                        if (value.trim() === "") {
                          setcurrAddstateError(true);
                        } else {
                          setcurrAddstateError(false);
                        }
                      }}
                      error={currAddstateError}
                      helperText={
                        currAddstateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current Address state
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={currAdddistrictError}>
                    <InputLabel id="outlined-adornment-districts">
                      current address District
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-district1"
                      label="current address District"
                      value={District}
                      onChange={(e) => {
                        setDistrict(e.target.value);
                        if (e.target.value === "") {
                          setcurrAdddistrictError(true);
                        } else {
                          setcurrAdddistrictError(false);
                        }
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {DistrictData !== "No data  found" &&
                        DistrictData.length > 0
                        ? DistrictData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>

                    {currAdddistrictError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select district data
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={currAddtalukaError}>
                    <InputLabel id="outlined-adornment-taluka">
                      current address Taluka
                    </InputLabel>
                    <Select
                      error={currAddtalukaError}
                      style={{ width: 250 }}
                      id="outlined-adornment-taluka1"
                      label="current address Taluka"
                      value={taluka}
                      disabled={TalukaData.length === 0}
                      onChange={(e) => {
                        setTaluka(e.target.value);
                        if (e.target.value === "") {
                          setcurrAddtalukaError(true);
                        } else {
                          setcurrAddtalukaError(false);
                        }
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {TalukaData !== "No data  found" &&
                        TalukaData.length > 0
                        ? TalukaData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.talukaName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {currAddtalukaError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select current address taluka
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={currAddgrampanchaytError}>
                    <InputLabel id="outlined-adornment-bank">
                      current address Location
                    </InputLabel>
                    <Select
                      error={currAddgrampanchaytError}
                      style={{ width: 250 }}
                      id="outlined-adornment-bank1"
                      label="current address location"
                      value={GramPanchayat}
                      disabled={GramPanchayatData.length === 0}
                      onChange={(e) => {
                        setGramPanchayat(e.target.value);
                        if (e.target.value === "") {
                          setcurrAddgrampanchaytError(true);
                        } else {
                          setcurrAddgrampanchaytError(false);
                        }
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {GramPanchayatData !== "No data  found" &&
                        GramPanchayatData.length > 0
                        ? GramPanchayatData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.locationName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>

                    {currAddgrampanchaytError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select current address location
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        pinError || pincode.match(/[A-Za-z+@#$&%!~]/) ||
                        (pincode.length !== 6 && pincode.length !== 0)
                      }
                      id="outlined-adornment-TrainerLocation"
                      label="current address Pin-Code "
                      value={pincode}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPincode(value);
                        if (value.length === 0) {
                          setpinError(false);
                        } else if (!/^\d{6}$/.test(value)) {
                          setpinError(true);
                        } else {
                          setpinError(false);
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      helperText={
                        pinError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter current address pincode
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>

                {/* Checkbox for "Same as Current Address" */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSameAsCurrent}
                      onChange={(e) => {
                        setIsSameAsCurrent(e.target.checked);
                        if (e.target.checked) {
                          setPermanantTalukaData(TalukaData)
                          // setPermanantPanchayatData(GramPanchayatData)
                          setPermanentAdd(currentAdd);
                          setpermanentAddlinetwo(currentAddlinetwo);
                          setpermanentstate(currentstate);
                          setPermanentDistrict(District);
                          setPermanentTaluka(taluka);
                          setPermanentGramPanchayat(GramPanchayat);
                          setpermanentPincode(pincode);

                          // Reset errors when checkbox is checked
                          setPermaAddError(false);
                          setPermaAddTwoError(false);
                          setPermaAddstateError(false);
                          setpermaAdddistrictError(false);
                          setpermaAddtalukaError(false);
                          // setpermaAddgrampanchayatError(false);
                          setPermapinError(false);
                        } else {
                          setPermanentAdd('');
                          setpermanentAddlinetwo('');
                          setpermanentstate('');
                          setPermanentDistrict('');
                          setPermanentTaluka('');
                          setPermanentGramPanchayat('');
                          setpermanentPincode('');

                          // Reset errors when checkbox is checked
                          setPermaAddError(true);
                          setPermaAddTwoError(true);
                          setPermaAddstateError(true);
                          setpermaAdddistrictError(true);
                          setpermaAddtalukaError(true);
                          // setpermaAddgrampanchayatError(true);
                          setPermapinError(true);
                        }
                      }}
                      name="sameAsCurrent"
                      color="primary"
                      disabled={
                        !currentAdd ||
                        !currentAddlinetwo ||
                        !currentstate ||
                        !District ||
                        !taluka ||
                        // !GramPanchayat ||
                        !pincode
                      }
                    />
                  }
                  label="Same as Current Address"
                />


                {/* for permanant address */}
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#3A3A56', color: 'white', padding: 10, letterSpacing: '1px' }}>Permanant Address Details</Typography>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-checkbox"
                      label="Permanent-Address line 1"
                      value={permanentAdd}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPermanentAdd(value);
                        if (value.trim().length === 0) {
                          setPermaAddError(true);
                        } else {
                          setPermaAddError(false);
                        }
                      }}
                      error={PermaAddError}
                      helperText={
                        PermaAddError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter permanant Address line 1
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-addressline2"
                      label="permanent Address line 2"
                      value={permanentAddlinetwo}
                      onChange={(e) => {
                        const value = e.target.value;
                        setpermanentAddlinetwo(value);
                        if (value.trim().length === 0) {
                          setPermaAddTwoError(true);
                        } else {
                          setPermaAddTwoError(false);
                        }
                      }}
                      error={PermaAddtwoError}
                      helperText={
                        PermaAddtwoError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter permanant Address line 2
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-state2"
                      label="permanent address state "
                      value={permanentstate}
                      onChange={(e) => {
                        const value = e.target.value;
                        setpermanentstate(value);
                        if (value.trim().length === 0) {
                          setPermaAddstateError(true);
                        } else {
                          setPermaAddstateError(false);
                        }
                      }}
                      error={PermaAddstateError}
                      helperText={
                        PermaAddstateError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter permanant Address state
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={permaAdddistrictError}>
                    <InputLabel id="outlined-adornment-perdistrict2">
                      Permanent District
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-perdistricts"
                      label="Permanent District"
                      value={PermanentDistrict}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPermanentDistrict(value);
                        if (value === "") {
                          setpermaAdddistrictError(true);
                        } else {
                          setpermaAdddistrictError(false);
                        }
                      }}
                      error={permaAdddistrictError}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantDistrictData !== "No data  found" &&
                        PermanantDistrictData.length > 0
                        ? PermanantDistrictData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.districtName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {permaAdddistrictError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select district data
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={permaAddtalukaError}>
                    <InputLabel id="outlined-adornment-pertaluka">
                      Permanent Taluka
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-pertalukas"
                      label="Permanent Taluka"
                      value={Permanenttaluka}
                      disabled={PermanantTalukaData.length === 0}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPermanentTaluka(value);
                        if (value === "") {
                          setpermaAddtalukaError(true);
                        } else {
                          setpermaAddtalukaError(false);
                        }
                      }}
                      error={permaAddtalukaError}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantTalukaData !== "No data  found" &&
                        PermanantTalukaData.length > 0
                        ? PermanantTalukaData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.talukaName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {permaAddtalukaError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select permanant address taluka
                      </span>
                    ) : null}
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={permaAddgrampanchayatError}>
                    <InputLabel id="outlined-adornment-bank">
                      Permanent Training Location
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-banks"
                      label="Permanent Location"
                      value={PermanentGramPanchayat}
                      disabled={PermanantPanchayatData.length === 0}
                      error={permaAddgrampanchayatError}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPermanentGramPanchayat(value);
                        if (value === "") {
                          setpermaAddgrampanchayatError(true);
                        } else {
                          setpermaAddgrampanchayatError(false);
                        }
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {PermanantPanchayatData !== "No data  found" &&
                        PermanantPanchayatData.length > 0
                        ? PermanantPanchayatData.map((option) => (
                          <MenuItem key={option._id} value={option}>
                            {option.locationName}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {permaAddgrampanchayatError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select permanant address
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap', justifyContent: 'center' }}>
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      error={
                        permapinError || permanentpincode.match(/[A-Za-z+@#$&%!~]/) ||
                        (permanentpincode.length !== 6 && permanentpincode.length !== 0)
                      }
                      id="outlined-adornment-peraddress1"
                      label="permanent address Pin-Code "
                      value={permanentpincode}
                      onChange={(e) => {
                        const value = e.target.value;
                        setpermanentPincode(value);
                        if (value.length === 0) {
                          setPermapinError(false);
                        } else if (!/^\d{6}$/.test(value)) {
                          setPermapinError(true);
                        } else {
                          setPermapinError(false);
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      helperText={
                        permapinError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter permanant address pincode
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                </div>

                {/* for password */}
                <Typography style={{ fontSize: 16, fontWeight: 700, backgroundColor: '#3A3A56', color: 'white', padding: 10, letterSpacing: '1px' }}>Password & Type of Role</Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                    , flexWrap: 'wrap'
                  }}
                >

                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={passwordval}
                      onChange={(e) => {
                        setPasswordval(e.target.value);
                        if (e.target.value === "") {
                          setPassError(true);
                        } else {
                          setPassError(false);
                        }
                      }}
                      error={passError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={(e) => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        passError ? (
                          <span style={{ color: "red", fontSize: 15 }}>
                            *Please Enter password
                          </span>
                        ) : null
                      }
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, marginRight: 3 }} error={usertypeError}>
                    <InputLabel id="outlined-adornment-bank2">
                      User-Type
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-banks2"
                      label="User-Type"
                      value={usertype}
                      onChange={(e) => {
                        setUserType(e.target.value);
                        if (e.target.value) {
                          setusertypeError(false);
                        }
                      }}

                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {User !== "No data  found" && User.length > 0
                        ? User.map((option) => (
                          <MenuItem key={option.id} value={option}>
                            {option.label}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {usertypeError ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select user type
                      </span>
                    ) : null}
                  </FormControl>

                  {/* New Changes at 06-08-2024 */}
                  <FormControl sx={{ m: 1, marginRight: 3 }}>
                    <TextField
                      style={{ width: 250 }}
                      id="outlined-adornment-fname"
                      label="Qualification"
                      value={qualification}
                      onChange={(e) => setQualification(e.target.value)}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, marginRight: 3 }} error={jobRoleErr}>
                    <InputLabel id="outlined-adornment-bank3">
                      Job Role
                    </InputLabel>
                    <Select
                      style={{ width: 250 }}
                      id="outlined-adornment-banks3"
                      label="Job Role"
                      value={jobRole}
                      onChange={(e) => {
                        setJobrole(e.target.value);
                        if (e.target.value) {
                          setJobroleErr(false);
                        }
                      }}
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                        },
                      }}
                    >
                      {jobRoleData.map((option) => (
                        <MenuItem key={option._id} value={option.trainingModuleName}>
                          {option.trainingModuleName}
                        </MenuItem>
                      ))
                      }
                    </Select>
                    {jobRoleErr ? (
                      <span style={{ color: "red", fontSize: 15 }}>
                        *Please select job role
                      </span>
                    ) : null}
                  </FormControl>


                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center", flexWrap: 'wrap'
                  }}
                >
                </div>
              </CardContent>
              {error ? (
                <Alert
                  severity="warning"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please fill requested form properly
                </Alert>
              ) : null}
              <Button
                variant="contained"
                style={{ margin: 10 }}
                endIcon={<SendIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </div>
        <Modal
          className={classes.middlePosition}
          open={userExist}
          onClose={(e) => {
            e.preventDefault();
            setUserExist(false)
          }}>
          <Paper className={classes.passmodal}>
            <Stack direction="row" justifyContent="space-between"
              alignItems="center" spacing={2}>
              <Stack direction="column">
                <Typography variant='h6' component='div'>This Mobile Number is already resgistered.
                </Typography>
              </Stack>
              <IconButton aria-label="delete" onClick={e => {
                e.preventDefault();
                setUserExist(!userExist);
              }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <center>
              <Button variant="contained" style={{ margin: 5 }} onClick={() => setUserExist(!userExist)} >OK</Button>
            </center>
          </Paper>
        </Modal>
      </div>
    </center >
  );
};
const useStyles = makeStyles((theme) => ({
  mainClass: {
    width: '100%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  card: {
    width: "800px",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  passmodal: {
    width: "450px",
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  profilemodal: {
    width: 'intrinsic',
    background: "white",
    borderRadius: "20px !important",
    margin: "20px",
    padding: "20px",
  },
  middlePosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: 'column'
  },
  input: {
    width: "70%",
    background: "white",
    marginTop: "20px",
    padding: "5px 15px",
  },
}));
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllDistrictData: () => dispatch(Action.getAllDistrictData()),
    getAllTalukaData: () => dispatch(Action.getAllTalukaData()),
    getAllgrampanchayatdata: () => dispatch(Action.getAllgrampanchayatdata()),
    getDistrictWiseTalukaData: (data) => dispatch(Action.getDistrictWiseTalukaData(data)),
    getTalukaWisePanchayat: (data) => dispatch(Action.getTalukaWisePanchayat(data)),
    getAllTrainingModuleData:()=>dispatch(Action.getAllTrainingModuleData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
