import { LOGIN_SUCCESS,LOGOUT,SET_TOKEN } from '../Constant/authConstant'
import { UPDATE_PROFILE } from "../Constant/adminConstant"
import { error } from 'ajv/dist/vocabularies/applicator/dependencies';

const initialState = {
    loading: false,
    userDetails: null,
    userToken:null,
    error: null,
};

const authReducer = (state = initialState, {type,payload}) => {
    // console.log('Auth Reducer: ', {type,payload});
    switch (type) {
        
        case LOGIN_SUCCESS:
            return{
                ...state,
                userDetails:payload,
                error:null
            };
        
        case SET_TOKEN:
            return{
                ...state,
                userToken:payload,
                error:null
            }

        case UPDATE_PROFILE:
            return{
                ...state,
                userDetails:payload,
                error:null
            }

        case LOGOUT:
             return {
            ...state,
            userDetails:null,
            userToken:null,
            error:null
        };
        default:
            return state
    }
};

export default authReducer
