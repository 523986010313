/* eslint-disable no-unused-vars */
module.exports = {
    async uploadImageFromBackend(newFileName, fileType, imageData) {
        try {
            let url = process.env.REACT_APP_BASE_URL + "/upload/photo";
            let formData = new FormData();
            formData.append('image', imageData); // Add the file
            formData.append('fileName', newFileName); // Add additional parameters
            // Send the file and data to the backend
            const uploadResponse = await fetch(url, {
                method: 'POST',
                body: formData, // FormData handles multipart encoding automatically
            });

            if (uploadResponse.ok) {
                const responseData = await uploadResponse.json();
                return responseData.url;
            } else {
                console.error('File upload failed');
                return "File upload failed";
            }
        } catch (error) {
            console.log("----error*****", error)
            return error;
        }
    },
    async getPresignedURL(newFileName, fileType, imageData) {
        // Step 1: Request a pre-signed URL from the server
        const response = await fetch(process.env.REACT_APP_PRESIGNEDURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fileName: newFileName,
                fileType: fileType,
            }),
        });
        const data = await response.json();
        if (!response.ok) {
            console.error('Error generating pre-signed URL:', data.error);
            return data.error;
        }

        const presignedUrl = data.url;
        // Step 2: Use the pre-signed URL to upload the file to S3
        const uploadResponse = await fetch(presignedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': fileType },
            body: imageData, // Upload the file directly
        });

        if (uploadResponse.ok) {
            let imageURL = uploadResponse.url.split("?Content-Type=");
            return imageURL[0];
        } else {
            console.error('File upload failed');
            return "File upload failed"
        }
    }

};
