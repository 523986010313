import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import logo from "../Assets/Image/gsda_logo.jpg"
import LogoutIcon from '@mui/icons-material/Logout';
import * as Action from '../actions/adminAction';
import * as Auth from '../actions/authActions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Profile from '../Assets/Image/Profile Admin.png'
import GroupIcon from '@mui/icons-material/Group';
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DomainIcon from '@mui/icons-material/Domain';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const drawerWidth = 240;

function DistrictCoordinator(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [Agentopen, setAgentOpen] = React.useState(false);
  const [Bankopen, setBankopen] = React.useState(false);
  const [GSTopen, setGSTopen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />

      <List
        sx={{
          marginTop: 3,
          height: '100vh',
          backgroundColor: '#3A3A56'
        }}
        style={{ marginTop: 25 }}
      >

        {/* Home/Rollout Plan */}
        <ListItem
          button
          onClick={() => {
            navigate("/district-coordinator/rollout-plan");
            props.setActiveList("/district-coordinator/rollout-plan")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/district-coordinator/rollout-plan" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><HomeIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Rollout Plan</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Grampanchayat Details */}
        <ListItem
          button
          onClick={() => {
            props.setActiveList("/district-coordinator/grampanchayat-details")
            navigate("/district-coordinator/grampanchayat-details")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/district-coordinator/grampanchayat-details" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Training Location Details</Typography>
            </ListItemButton>
          </div>
        </ListItem>



        {/* Approved Traning Details */}
        <ListItem
          button
          onClick={() => {
            props.setActiveList("/district-coordinator/approve-traning-details")
            navigate("/district-coordinator/approve-traning-details")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/district-coordinator/approve-traning-details" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}
        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >Approved Training Details</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Rejected Training Details  */}
        <ListItem
          button
          onClick={() => {
            props.setActiveList("/district-coordinator/rejected-traning-details")
            navigate("/district-coordinator/rejected-traning-details")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}
          style={props.ActiveList === "/district-coordinator/rejected-traning-details" ? {
            backgroundColor: '#f4c03e',
            border: '1px solid black',
            color: 'white',
            fontSize: 24
          } : {
            color: "white",
          }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon><ReceiptIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }}>Pending Documents</Typography>
            </ListItemButton>
          </div>
        </ListItem>

        {/* Logout */}
        <ListItem
          button
          onClick={() => {
            props.logout()
            navigate("/")
          }}
          sx={{
            "&:hover": {
              backgroundColor: '#f4c03e',
              border: '1px solid black',
              fontSize: 24
            },
          }}

          style={{ color: 'white' }}

        >
          <div className={classes.back}>
            <ListItemButton>
              <ListItemIcon ><LogoutIcon style={{ color: 'white', fontSize: 30 }} /></ListItemIcon>
              <Typography style={{ fontSize: 20 }} >LogOut</Typography>
            </ListItemButton>
          </div>
        </ListItem>

      </List>
      <Divider />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (props.userDetails === null) {
    navigate("/")
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% -0px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          // border: '1px solid #3A3A56',
          zIndex: 1201,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Toolbar style={{ width: '100%', flex: 1 }}>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} style={{ width: 200, height: 100, padding: 10 }} />
          {/* <Typography style={{ fontSize: 34, color: 'green', fontWeight: 600 }}>MBCOWW</Typography> */}
        </Toolbar>
        <div style={{ flex: 1 }}>
          <Typography style={{ fontSize: 30, color: 'green', fontWeight: 600, margin: '5px', marginTop: '25px' }}>District Coordinator</Typography>
        </div>

        <img src={props.userDetails?.profilePicURL ? props.userDetails?.profilePicURL : Profile} style={{ width: 70, height: 60, margin: 20, cursor: 'pointer', border: '1px solid black', borderRadius: '50%' }} onClick={() => {
          navigate('/district-coordinator/Profile'); props.setActiveList('/district-coordinator/Profile')
        }} />

      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              "::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* <Typography paragraph> */}
        <Outlet />
        {/* </Typography> */}
      </Box>
    </Box>
  );
}

DistrictCoordinator.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const useStyles = makeStyles((theme) => ({
  back: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "240px",
    minHeight: "70px",
    borderBottomLeftRadius: "30px",
    borderTopLeftRadius: "30px",
  },
  active: {
    backgroundColor: '#f4c03e',
    border: '1px solid black',
    fontSize: 24
  }
}));

const mapStateToProps = (state) => {
  return {
    ActiveList: state.admin.ActiveList,
    userDetails: state.auth.userDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveList: (data) => dispatch(Action.setActiveList(data)),
    logout: () => dispatch(Auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DistrictCoordinator)

